/* ----------------------------------------------------------------------------
  Navigation Menu
----------------------------------------------------------------------------- */
#nav-wrap {
  background: $theme-primary;
}
#menutoggle {
  display: block;
  margin: 0;
  padding: 0 22px;
  width: auto;
  color: #333;
  height: 45px;
  font-size: 18px;
  line-height: 46px;
  text-shadow: 0 1px 0 rgba(0, 0, 0, 0.35);
  text-align: left;
  text-transform: uppercase;
  cursor: pointer;
  position: absolute;
  right: 0;
  bottom: 35px;
  border-radius: 4px 4px 0 0;
  transition: background 0.2s linear 0s;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  span {
    display: none;
  }
  &::before {
    display: inline-block;
    margin-left: 0;
    width: 18px;
    height: 45px;
    line-height: 48px;
    content: '\e826';
    color: inherit;
    font-family: 'icons';
    text-align: center;
    font-size: 21px;
    vertical-align: top;
  }
  &.is-active:before {
    content: '\e844';
  }
  &.is-active + ul {
    max-height: 1000px;
    opacity: 1;
    transition:
      max-height 0.3s ease-in-out,
      opacity 0.25s ease-in-out;
  }
}
#nav {
  clear: both;
  margin: 0;
  width: 100%;
  height: 0;
  overflow: visible;
  position: relative;
  z-index: 1000;
  border-top: 1px solid $theme-primary;
  ul {
    float: none;
    display: block;
    margin: 0;
    padding: 0;
    width: auto;
    opacity: 0;
    max-height: 0;
    background: $theme-primary;
    overflow: hidden;
    transition:
      max-height 0.25s cubic-bezier(0, 0.7, 0, 1),
      opacity 0.2s ease-in-out;
    border-radius: 0 0 4px 4px;
    top: 1px;
    border: 0;
    position: relative;
    &.submenu {
      margin: 0;
      padding: 0;
      width: 100%;
      max-height: 0;
      background: none;
      opacity: 1;
      position: relative;
      top: 0;
      transition: max-height 0.15s linear 0s;
      a {
        padding-left: 40px;
        background: none;
        &:before {
          display: inline-block;
          margin-right: 5px;
          margin-left: -15px;
          width: 10px;
          height: inherit;
          content: '\00BB';
          color: inherit;
          font-family: 'icons';
          text-align: center;
          font-size: inherit;
          vertical-align: top;
        }
      }
      span.submenu.level-2 {
        background-color: lighten($theme-nav-primary, 4.5%);
        display: block;
        position: absolute;
        width: 55px;
        height: 44px;
        right: 0;
        top: 0;
        border-left: 1px solid lighten($theme-nav-primary, 4.5%);
        &.is-active {
          background-color: lighten($theme-nav-primary, 7.5%);
        }
      }
      ul.submenu {
        background-color: lighten($theme-nav-primary, 7.5%);
        border-radius: 0;
        // background-color: rgba(255, 255, 255, 0.05);
        li {
          a {
            border-top: 1px dotted $theme-nav-primary;
            padding-left: 40px;
            transition: padding-left 0.25s cubic-bezier(0, 0.7, 0, 1);
            &::before,
            &::after {
              display: none;
            }
            &:hover {
              padding-left: 45px;
            }
          }
        }
      }
    }
  }
  li {
    display: block;
    margin: 0;
    padding: 0;
    height: auto;
    overflow: hidden;
    border: 0;
    border-top: 1px dotted lighten($theme-primary, 7.5%);
    &:first-child {
      border-top: 0;
    }
    &.onmobile {
      display: block;
    }
  }
  a,
  span.submenu {
    float: none;
    display: block;
    margin: 0;
    padding: 9px 20px;
    height: auto;
    color: #fff;
    line-height: 1.6em;
    text-align: left;
    text-decoration: none;
    font-size: 16px;
    font-weight: normal;
    border: 0;
    cursor: pointer;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    transition: background 0.17s linear 0s;
  }
  span.submenu:after,
  a.submenu:after {
    float: right;
    margin-left: 10px;
    width: 15px;
    height: inherit;
    content: '\e817';
    color: inherit;
    font-family: 'icons';
    text-align: center;
    font-size: 105%;
    vertical-align: top;
  }
  span.submenu.is-active:after,
  a.submenu.is-active:after {
    content: '\e816';
  }
  a:hover,
  a.is-active,
  li:hover span.submenu,
  li:hover a.submenu,
  ul.submenu a:hover,
  ul.submenu a.is-active {
    background: lighten($theme-primary, 7.5%);
  }
}
.sidenav {
  height: 100%;
  width: 0;
  z-index: 10000;
  top: 0;
  left: -100%;
  overflow-x: hidden;
  box-shadow: 3px 0px 4px #686868;
  transition: 0.3s;
  max-width: 500px;
  &.is-active {
    width: 100%;
    left: 0;
  }
  .logo {
    margin-bottom: 10px;
    border: 0;
    width: 85%;
    img {
      margin-right: 7px;
      vertical-align: middle;
      width: auto;
      height: auto;
      max-width: 50px;
    }
  }
  #closebtn {
    top: 15px;
    right: 15px;
    font-size: 32px;
    line-height: 32px;
    padding: 3px 10px;
    background: $theme-primary;
    &:hover {
      background: darken($theme-primary, 5%);
    }
  }
  ul.submenu {
    list-style: none;
    padding-left: 15px;
    max-height: 0;
    transition: max-height 0.15s linear 0s;
    padding: 0;
    &.active-mobile {
      max-height: inherit;
      padding-left: 15px;
    }
    li {
      margin-bottom: 0;
      padding-bottom: 0;
      a {
        font-size: 14px;
        font-weight: 400;
        color: #4c4b4b;
      }
      &:hover > a {
        color: #000;
      }
    }
  }
  span.submenu.is-active + ul.submenu {
    max-height: 1000px;
    overflow-y: visible;
  }
  ul {
    & > li,
    & > span li {
      padding: 2px 0;
      overflow: hidden;
      & > a,
      & > span {
        border: 0;
        color: $theme-primary;
        font-weight: bold;
        font-size: 18px;
        padding: 2px 0;
        cursor: pointer;
        &.submenu {
          &:after {
            content: '\e817';
          }
          &.is-active:after {
            content: '\e816';
          }
        }
        &:after {
          display: inline-block;
          margin-left: 10px;
          width: inherit;
          height: inherit;
          content: '\e815';
          color: $theme-secondary;
          font-family: icons;
          font-weight: 400;
          text-align: center;
          font-size: 16px;
          line-height: 32px;
          vertical-align: top;
        }
        &:hover {
          color: $theme-secondary;
        }
      }
      & > a {
        &:after {
          line-height: 30px;
          margin-bottom: 7px;
        }
      }
      ul > li {
        padding: 0px;
        ul > li {
          margin-top: 0;
        }
        a {
          padding: 0px;
        }
      }
      ul li a:after {
        line-height: 26px;
      }
    }
  }
  .support {
    font-size: 18px;
    .livechat,
    .phone {
      &:before {
        display: inline-block;
        margin-right: 10px;
        width: inherit;
        height: inherit;
        content: '\e830';
        color: inherit;
        font-family: icons;
        font-weight: 400;
        text-align: center;
        font-size: 105%;
        vertical-align: top;
      }
    }
    .phone:before {
      content: '\e829';
    }
  }
  .login {
    background: $theme-primary;
    border: 0;
    padding: 5px 20px;
    border-radius: 50px;
    font-size: 18px;
    &:hover {
      background: darken($theme-primary, 5%);
    }
  }
}
#overlay {
  position: fixed;
  display: none;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9999;
  transition: 0.3s;
  opacity: 0;
  &.is-active {
    display: block;
    opacity: 1;
  }
}

@media (min-width: 36em) {
  /* Small devices (landscape phones, 576px and up) */
  /*@media (min-width: 48em) { /* Medium devices (tablets, 768px and up) */
  #menutoggle {
    padding: 0 15px;
    span {
      display: inline-block;
    }
    &:before {
      margin-right: 10px;
    }
  }
  .sidenav {
    &.is-active {
      width: 90%;
    }
  }
}
@media (min-width: 75em) {
  /* Large devices (desktops, 992px and up) */
  #menutoggle {
    display: none;
  }
  #nav {
    height: 45px;
    background: $theme-primary;
    position: relative;
    z-index: 99;
    border: 0;
    border-radius: 4px;
    ul {
      display: block !important;
      float: left;
      width: 100%;
      height: auto;
      opacity: 1;
      margin: 4px 0;
      position: relative;
      z-index: 10;
      top: 0;
      overflow: visible;
      &.submenu {
        float: left;
        margin: 0;
        padding: 0;
        width: 250px;
        max-height: 0;
        position: absolute;
        opacity: 0;
        background: lighten($theme-primary, 7.5%);
        top: 95%;
        z-index: 10;
        border-radius: 0 3px 3px 3px;
        box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2);
        transition:
          max-height 0.2s linear 0s,
          padding 0.2s linear 0s,
          opacity 0.2s linear 0s;
        overflow: hidden;
        overflow-y: auto;
        li {
          float: none;
          display: block;
          margin: 0;
          padding: 0;
          border: 0;
          height: auto;
          &:first-child a {
            border: 0;
          }
        }
        a {
          float: none;
          display: block;
          margin: 0;
          padding: 7px 15px 7px 28px;
          height: auto;
          text-transform: none;
          text-align: left;
          line-height: 1.5em;
          border-radius: 0;
          border: 0;
          border-top: 1px dotted $theme-primary;
        }
        span.submenu.level-2 {
          width: 50px;
          height: 35px;
          top: 1px;
          border-radius: 0 !important;
          border-bottom: 0;
          background-color: lighten($theme-nav-primary, 10%);
          &:hover {
            background-color: lighten($theme-nav-primary, 4.5%);
          }
          &:after {
            width: 100%;
            display: block;
          }
          &.is-active {
            background-color: lighten($theme-nav-primary, 4.5%);
            & + ul.submenu {
              max-height: 300px !important;
              overflow-y: auto;
            }
          }
        }
        ul.submenu {
          float: none;
          display: block;
          position: relative;
          padding: 0 !important;
          box-shadow: none;
          max-height: 0 !important;
          overflow: hidden;
          li {
            overflow: hidden;
            a {
              border-radius: 0;
              border-top: 1px dotted $theme-nav-primary;
              padding-left: 28px !important;
              background-color: lighten($theme-nav-primary, 4.5%);
              transition: padding-left 0.25s cubic-bezier(0, 0.7, 0, 1);
              &:hover,
              &.is-active {
                padding-left: 33px !important;
                background-color: $theme-nav-primary;
              }
            }
          }
        }
      }
    }
    li {
      float: left;
      margin: 0 3px;
      padding: 0;
      height: 37px;
      width: auto;
      min-width: auto;
      position: relative;
      border: 0;
      overflow: visible;
      &:first-child {
        margin-left: 0;
        border-left: 0;
      }
      &.last {
        border-right: 0;
      }
      &.right {
        float: right;
        margin-right: 0;
        border-right: 0;
        ul.submenu,
        ul.submenu.is-active {
          width: 220px;
          right: 0;
          left: auto;
          border-radius: 3px 0 3px 3px;
        }
      }
      &.onmobile {
        display: none;
      }
      &:hover {
        z-index: 11;
      }
    }
    a,
    span.submenu {
      float: left;
      margin: 0;
      padding: 0 10px;
      height: 37px;
      border: 0;
      color: #fff;
      font-weight: normal;
      font-size: 14px;
      line-height: 39px;
      text-align: center;
      text-transform: uppercase;
      border-radius: 3px;
      transition: background 0.2s linear 0s;
      cursor: pointer;
    }
    span.submenu:after,
    a.submenu:after {
      display: none;
    }
    a:hover,
    a.is-active,
    li:hover span.submenu,
    li:hover a.submenu,
    li:hover a.submenu,
    ul.submenu a:hover,
    span.submenu.is-active,
    a.submenu.is-active {
      background: darken($theme-primary, 7.5%);
    }
    li:hover a.submenu,
    li:hover span.submenu,
    li:hover a.submenu,
    span.submenu.is-active.hover,
    a.submenu.is-active.hover {
      border-radius: 3px 3px 0 0;
      -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    }
    li:hover ul.submenu,
    ul.submenu.is-active {
      padding: 3px 0;
      left: 0;
      max-height: 360px;
      opacity: 1;
    }
    li.right:hover {
      ul.submenu,
      ul.submenu.is-active {
        right: 0;
        left: auto;
      }
    }
    ul.submenu li a:hover,
    ul.submenu li a.is-active {
      background: $theme-primary;
    }
  }
}
