/* --------------------------------------------------------------
    Import CSS Dependencies
-------------------------------------------------------------- */
@import 'colors';
@import 'icons';
@import 'fonts';
@import 'default';
@import 'nav';
@import 'buttons';
/* --------------------------------------------------------------
    Start Project CSS
-------------------------------------------------------------- */
html {
  position: relative;
  height: 100%;
  background-color: #fff;
  // overflow-y: scroll;
  // -webkit-font-smoothing: antialiased;
  // -moz-osx-font-smoothing: grayscale;
}
body {
  height: 100%;
  font-family: var(--font-family);
  text-rendering: optimizeLegibility;
  color: #555555;
}
.wrap {
  padding: 30px 0;
}
.container-fluid.but-fixed {
  max-width: 1356px;
}
@media (min-width: 1400px) {
  .container-fluid.but-fixed {
    padding-right: calc(var(--bs-gutter-x) * 0.5);
    padding-left: calc(var(--bs-gutter-x) * 0.5);
  }
}
.footer {
  padding-top: 20px;
  padding-bottom: 15px;
  width: 100%;
  background-color: #f5f5f5;
  color: #666;
  border-top: 1px solid #e4e4e4;
  font-size: 0.9rem;
  p {
    margin: 0 0 5px 0;
  }
  p.trustmark {
    display: none;
    margin: 0;
  }
  a {
    color: #666;
  }
  .social a {
    border: 0;
  }
  a.social {
    height: 50px;
    width: 25px;
    color: #555 !important;
    background: none;
    font-size: 18px;
  }
  a.social:hover,
  a.social:active {
    font-size: 24px;
    background: none;
  }
  img {
    display: inline-block;
  }
  #trustwaveSealImage {
    margin: 0 10px 30px 0;
  }
  img.bbb {
    margin: 0 0 30px 10px;
  }
  .footer-links {
    list-style-type: none;
    li {
      padding: 2px 0;
    }
  }
}

/* --------- asc styles --------- */
#header,
#sideNav {
  background: rgba(255, 255, 255, 0.8);
  a.logo {
    border: 0;
    width: 80%;
    max-width: 650px;
    img {
      margin-right: 5px;
      width: 75%;
      height: auto;
      max-width: 225px;
    }
  }
  #support {
    margin-top: 40px;
    p {
      margin: 2px 12px 0 0;
      strong {
        font-size: 16px;
      }
    }
    a.login {
      padding: 0 12px 2px 12px;
      color: #fff;
      background: $theme-primary;
      text-decoration: none;
      border-radius: 0 0 4px 4px;
      border: 0;
      &:before {
        display: inline-block;
        margin-right: 5px;
        width: inherit;
        height: inherit;
        content: '\e828';
        color: inherit;
        font-family: 'icons';
        font-weight: normal;
        text-align: center;
        font-size: 105%;
        vertical-align: top;
      }
      &:hover {
        background: lighten($theme-primary, 7.5%);
      }
    }
    span.phone {
      padding-right: 8px;
      border-right: 1px dotted #333;
    }
    a.livechat {
      color: #555;
      &:before {
        display: inline-block;
        margin-right: 5px;
        width: inherit;
        height: inherit;
        content: '\e830';
        color: inherit;
        font-family: 'icons';
        font-weight: normal;
        text-align: center;
        font-size: 105%;
        vertical-align: top;
      }
    }
  }
}

#sideNav {
  span.big,
  span.small {
    display: inline;
    font-size: 1.5em;
    font-weight: 500;
    color: #333;
    line-height: 1em;
    vertical-align: middle;
  }
  span.big {
    font-weight: 600;
    font-family: var(--font-family);
    line-height: 1.2;
  }
  span.small {
    display: none;
    margin-top: 0;
    font-size: 16px;
    letter-spacing: 0.03rem;
    font-family: var(--font-family);
    font-weight: 600;
  }
  .search-in-nav {
    box-shadow: none;
  }
}

#top-wrap {
  padding: 2px 10px 4px;
  background: $red;
  color: #fff;
  a {
    color: #fff;
  }
}

#banner-wrap {
  background: #000 url(../images/home-bg.jpg) no-repeat;
  background-size: cover;
  &.paulson {
    background: #000 url(../images/paulson/paulson-bg.jpg) no-repeat;
    background-size: cover;
  }
}

#calculator-wrap .calculator:before {
  display: inline-block;
  margin-right: 10px;
  width: 52px;
  height: 55px;
  content: '';
  background: url(../images/cost-calculator.png) no-repeat 0 0 / cover;
  vertical-align: middle;
}

#testimonial-wrap {
  hr {
    color: #fff;
  }
}

.webinar-item {
  background: #f7f7f7;
  padding: 20px;
  margin-bottom: 10px;
  .recorded-date {
    font-size: 0.9em;
    font-weight: 600;
  }
  p:last-child,
  ul:last-child {
    margin-bottom: 0;
  }
}

/* Extra Utilities */
.bg-black {
  background-color: rgba(0, 0, 0, 0.75) !important;
}
.bg-blue {
  background: $blue;
  a {
    color: #fff;
  }
}
.h-border-blue {
  padding-bottom: 3px;
  border-bottom: 2px solid $blue;
}
hr.callout {
  width: 125px;
  height: 1px;
  background-color: #555;
}
.fw-semibold {
  font-weight: 600;
}
.shadow-md {
  box-shadow: 0 0.15rem 0.55rem rgba(0, 0, 0, 0.175) !important;
}

.boxed-hover {
  transition:
    box-shadow 0.15s ease-in-out,
    transform 0.15s ease-in-out;
  &:hover {
    box-shadow: 0 3px 10px rgba(27, 141, 222, 0.2);
    transform: translateY(-1px);
  }
}
.text-white a {
  color: #fff;
}

.clearfix-both {
  clear: both;
}
/* ARLO STYLES */

@-webkit-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.arlo-loading-overlay {
  position: fixed;
  z-index: 999;
  height: 2em;
  width: 2em;
  overflow: show;
  margin: auto;
  bottom: 50%;
  top: 50%;
  left: 50%;
  right: 50%;
  &:before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.3);
  }
  &:not(:required) {
    font: 0/0 a;
    color: transparent;
    text-shadow: none;
    background-color: transparent;
    border: 0;
    &:after {
      content: '';
      display: block;
      font-size: 10px;
      width: 1em;
      height: 1em;
      margin-top: -0.5em;
      -webkit-animation: spinner 1500ms infinite linear;
      animation: spinner 1500ms infinite linear;
      border-radius: 0.5em;
      box-shadow:
        rgba(0, 0, 0, 0.75) 1.5em 0 0 0,
        rgba(0, 0, 0, 0.75) 1.1em 1.1em 0 0,
        rgba(0, 0, 0, 0.75) 0 1.5em 0 0,
        rgba(0, 0, 0, 0.75) -1.1em 1.1em 0 0,
        rgba(0, 0, 0, 0.75) -1.5em 0 0 0,
        rgba(0, 0, 0, 0.75) -1.1em -1.1em 0 0,
        rgba(0, 0, 0, 0.75) 0 -1.5em 0 0,
        rgba(0, 0, 0, 0.75) 1.1em -1.1em 0 0;
    }
  }
}

.arlo {
  hr {
    margin: 5px 0;
  }

  .arlo-clearfix {
    zoom: 1;
    &:after {
      visibility: hidden;
      display: block;
      font-size: 0;
      content: ' ';
      clear: both;
      height: 0;
    }
  }

  * {
    box-sizing: border-box;

    &:first-child + html {
      .arlo-clearfix {
        zoom: 1;
      }
    }
  }

  .arlo-bg-color-primary {
    background-color: $blue;
  }

  .arlo-bg-color-secondary {
    background-color: $blue;
  }

  .arlo-bg-color-highlight {
    background-color: $blue;
  }

  .arlo-bg-color-filters {
    background-color: $blue;
  }

  .arlo-bg-color-normal {
    background-color: #f5f5f5;
  }

  .arlo-bg-color-darker {
    background-color: #ededed;
  }

  .arlo-bg-color-darkest {
    background-color: #e1e1e1;
  }

  .arlo-text-color-primary {
    color: #fffdf0;
  }

  .arlo-text-color-secondary {
    color: $blue;
  }

  .arlo-text-color-contrast {
    color: #fff;
  }

  .arlo-text-color-lightest {
    color: #bcbcbc;
  }

  .arlo-text-color-light {
    color: #777;
  }

  .arlo-text-color-normal {
    color: #555;
  }

  .arlo-text-color-dark {
    color: #555;
  }

  .arlo-text-color-link {
    color: $blue;
  }

  .arlo-font-primary,
  .arlo-font-secondary,
  .arlo-font-special {
    font-family: var(--font-family);
  }
}
a.arlo-referral-link {
  display: none !important;
}
.font-14 {
  font-size: 14px;
}

.modal-header {
  background-color: #0098d7;
}

.sidebar-courses {
  h3 {
    color: #555;
    a {
      display: block;
      &:hover {
        background: darken($theme-light, 5%);
        border: 0;
      }
    }
  }
  ul {
    li {
      border-top: 1px dotted #ccc;
      padding: 0;
      margin: 0;
      a {
        border: 0;
        display: block;
        padding: 10px 1rem;
        &:hover {
          background: #e7e7e7;
        }
      }
    }
  }
}

.mw-150 {
  max-width: 150px;
}

/* ----------------------------------------------------------------------------
    Page Media Queries
----------------------------------------------------------------------------- */
// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) {
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {
  // this adds an extra padding to fluid containers
  [class*='container-'] {
    padding-right: var(--bs-gutter-x, 1.5rem);
    padding-left: var(--bs-gutter-x, 1.5rem);
  }
  .container-fluid.but-fixed {
    padding-left: 30px;
    padding-right: 30px;
  }
  #header a.logo {
    img {
      margin-right: 15px;
    }
  }
  #banner-wrap {
    background: #000 url(../images/home-bg-lg.jpg) no-repeat;
    background-size: cover;
    &.paulson {
      background: #000 url(../images/paulson/paulson-bg-lg.jpg) no-repeat 50% 20%;
      background-size: cover;
    }
  }
}

// roughtly 900px/16px
@media only screen and (min-width: 56.25em) {
  #footer p.trustmark {
    display: block;
  }
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) {
  // this reverts the extra padding added to fluid containers
  [class*='container-'] {
    padding-right: var(--bs-gutter-x, 0.75rem);
    padding-left: var(--bs-gutter-x, 0.75rem);
  }
  #header a.logo {
    width: 25%;
  }
  .btn-lg {
    max-width: 350px;
  }
  .border-lg-0 {
    border: 0 !important;
  }
}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {
}
